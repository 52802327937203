// Customizable Area Start
import React, {
  useEffect,
  useRef,
  useState,
  RefObject,
  Suspense,
  ChangeEvent,
} from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  useMediaQuery,
  Box,
  Divider,
  InputAdornment,
  TextField,
  ListItem,
  List,
  IconButton,
  CircularProgress,
  Modal,
  Grid,
  Badge,
  styled
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import theme from "../../utilities/src/theme";
import { Appheader as useStyles } from "./styles/Appheader.web";
import { ServiceList, configJSON } from "./AppheaderController.web";
import { Skeleton } from "@material-ui/lab";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import crossIcon from  "../assets/cross.svg";
import { Link } from "react-router-dom";
import { MenuBarIcon, SearchBarIcon, SearchBarIconWeb } from "./assets";
import { cartBagIcon } from "../../appointmentmanagement/src/assets";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
interface Props {
  logo: string | null;
  storeName: string;
  confirmSearch: (searchQuery: string) => void;
  searchQuery: string;
  searchServiceList: (queryRef: RefObject<HTMLInputElement>) => void;
  searchListSuggestionData: Array<ServiceList>;
  searchElementTitleFunction: (title: string, isService: boolean) => void;
  openClose: boolean;
  closeSearch: () => void;
  searchRef1: string;
  isServiceProvider: boolean;
  renameItem1: string;
  renameItem2: string;
  renameItem3: string;
  show_business_name: boolean;
  toogleModal: () => void;
  isModalOpen: boolean;
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBookingIdChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  emailError: string;
  bookingIdError: string;
  loading: boolean;
  buildCardID: string;
  handleNavigation: (route: string) => void;
  color: string
  font: string;
  isSearchEnabled: boolean;
  templateName:string;
  banner_background:string;
  headerName:string;
  bannerType:string;
  handleCartNavigation:()=>void;
  cartLength: number;
}

const Appheader: React.FC<Props> = ({
  logo,
  storeName,
  confirmSearch,
  searchQuery,
  searchServiceList,
  searchListSuggestionData,
  searchElementTitleFunction,
  openClose,
  closeSearch,
  searchRef1,
  isServiceProvider,
  renameItem1,
  renameItem2,
  renameItem3,
  show_business_name,
  toogleModal,
  isModalOpen,
  handleEmailChange,
  handleBookingIdChange,
  handleSubmit,
  emailError,
  bookingIdError,
  loading,
  handleNavigation,
  buildCardID,
  color,
  font,
  isSearchEnabled,
  templateName,
  banner_background,
  headerName,
  bannerType,
  handleCartNavigation,
  cartLength
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearcBarOpen, setIsSearchBarOpen] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [isBtnVisible, setIsBtnVisible] = useState(false);

  interface DrawerItem {
    text: string;
    link: string;
    mixpanel: string;
  }
  const isPreviewMode = window.location.href.includes("/preview");
  const drawerItems: DrawerItem[] = isServiceProvider
    ? [
      {
        text: renameItem1,
        link: isPreviewMode ? "ServicesPreview" : "Services",
        mixpanel: "webcustomer_navigation_services_button",
      },
      {
        text: renameItem2,
        link: isPreviewMode ? "ServiceProvidersPreview" : "ServiceProviders",
        mixpanel: "webcustomer_navigation_staffListing_button",
      },
      {
        text: renameItem3,
        link: isPreviewMode? "GalleryPreview" : "Gallery",
        mixpanel: "webcustomer_navigation_gallery_button",
      },
    ]
    : [
      {
        text: renameItem1,
        link: isPreviewMode ? "ServicesPreview" : "Services",
        mixpanel: "webcustomer_navigation_services_button",
      },
      {
        text: renameItem3,
        link: isPreviewMode? "GalleryPreview" : "Gallery",
        mixpanel: "webcustomer_navigation_gallery_button",
      },
    ];

  const toggleDrawer = (itemClick: boolean = false) => {
    setIsDrawerOpen(!isDrawerOpen);
    if (itemClick && isModalOpen) {
      toogleModal();
    }
  };

  const handleMobileManageBookingClick = () => {
    mixpanel.track(`${configJSON.navigationManageBooking}`, { buildCardID: buildCardID });
    setIsDrawerOpen(!isDrawerOpen);
    toogleModal();
  };

  const toggleSearchBar = () => {
    searchForCatalogue;
    setIsSearchBarOpen(!isSearcBarOpen);
  };

  const searchForCatalogue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchRef.current) {
      mixpanel.track(`webcustomer_navigation_search`, { buildCardID: buildCardID });
      setIsSearchBarOpen(false);
      const searchQuery = searchRef.current.value;
      searchRef.current.value = "";
      searchQuery.trim() !== "" && confirmSearch(searchQuery);
    }
  };

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  }, [searchQuery]);

  useEffect(() => {
    const themesandfonts =  localStorage.getItem("theme_setting_data");
    if(themesandfonts != null){
      setIsBtnVisible(true)
    }
  })
  const renderNavigationHeader = () =>{
    return(
      <>
      <Grid container className={classes.HeaderContainer}>
        <Grid xs={4}>1</Grid>
        <Grid xs={4}>
            <Box className={classes.navigationContainer}>
              <Box className={classes.logoAppHeader}>
                <Box onClick={() => handleNavigation("Home")} className={classes.homeLink} data-test-id="logo">
                  {logo &&
                    <Suspense fallback={<CircularProgress />}>
                      <img className={classes.logo} src={logo} height={"50px"} alt="image loading" />
                    </Suspense>}
                  {(show_business_name || !logo && storeName) && <Typography className={classes.title} style={{ color: color, fontFamily: font }}>
                    {storeName}
                  </Typography>
                  }
                </Box>
              </Box>
              <Box>
                <Box className={classes.navigationItems}>
                  {drawerItems.map((item, index) => (
                    <Box
                      key={index}
                      className={classes.link}
                      onClick={() => {
                        mixpanel.track(`${item.mixpanel}`, { buildCardID: buildCardID });
                        handleNavigation(item.link);
                      }}
                    >
                      <Typography className={classes.linkText}>
                        {item.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
        </Grid>

        <Grid xs={4}>
        <Box className={classes.SearchContainer}>
              {isSmallScreen ? (
                isSearchEnabled &&
                <SearchIcon
                  className={classes.searchIcon}
                  onClick={toggleSearchBar}
                />
              ) : (
                <>
                  {
                    isSearchEnabled &&
                    <Typography
                      component="div"
                      className={classes.searchbarWrapperDiv}
                    >
                      <Box>
                        <TextField
                          style={{ fontSize: "14px", position: "relative", zIndex: 9 }}
                          variant="outlined"
                          inputRef={searchRef}
                          placeholder="Search"
                          onKeyDown={searchForCatalogue}
                          inputProps={{ className: classes.searchBar }}
                          onChange={() => searchServiceList(searchRef)}
                          onKeyDownCapture={closeSearch}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                classes={{
                                  positionStart: classes.inputAdornment,
                                }}
                              >
                                <SearchIcon className={classes.searchIcon} />
                              </InputAdornment>
                            ),
                            classes: {
                              adornedStart: classes.searchBarIconAdornment,
                            },
                          }}
                        />
                      </Box>
                      <Box
                        className={
                          openClose
                            ? classes.closeBox
                            : classes.serarchListSuggestionHide
                        }
                        onClick={closeSearch}
                      ></Box>
                      <Box
                        className={
                          openClose
                            ? classes.serarchListSuggestion
                            : classes.serarchListSuggestionHide
                        }
                      >
                        {searchListSuggestionData.map(
                          (element: ServiceList) => {
                            const isMatch = element.title
                              ?.toLowerCase()
                              .startsWith(searchRef1.toLowerCase());
                            return (
                              <Typography
                                key={element.id}
                                onClick={() =>
                                  searchElementTitleFunction(
                                    element.title,
                                    element.is_service
                                  )
                                }
                                className={classes.titleName}
                              >
                                <Box
                                  className={
                                    isMatch
                                      ? classes.boldText
                                      : classes.normalText
                                  }
                                >
                                  {isMatch ? (
                                    <Box>
                                      <span className={classes.normalText}>
                                        {searchRef1}
                                      </span>
                                      <span className={classes.boldText}>
                                        {element.title.substring(
                                          searchRef1.length
                                        )}
                                      </span>
                                    </Box>
                                  ) : (
                                    element.title
                                  )}
                                </Box>
                              </Typography>
                            );
                          }
                        )}
                      </Box>
                    </Typography>
                  }
                  {isBtnVisible ?
                    <Box
                      className={classes.link}
                      onClick={toogleModal}
                      data-test-id="manageBookingBtn"
                    >
                      <Typography className={classes.bookNowButton}>
                        {configJSON.manageBooking}
                      </Typography>
                    </Box>
                    :
                    <Box className={classes.skeletonBox}>
                      <Skeleton
                        variant="rect"
                        style={{
                          position: "absolute",
                          width: "160px",
                          height: "35px",
                        }}
                        animation="wave"
                      />
                    </Box>
                  }
                </>
              )}
            </Box>
        </Grid>
      </Grid>
      </>
    )
  }

  const getTypographyStyles = (isSmallScreen: boolean): React.CSSProperties => ({
    fontSize: isSmallScreen ? '18px' : '24px',
    lineHeight: isSmallScreen ? '26px' : '32px',
    padding: isSmallScreen ? '8px' : '5px',
    width: isSmallScreen ? '311px' : '371px',
  });

  const getBgcolor=(templateName:string)=>{

  }
 const CustomCartBadge = styled(Badge)({
    display: 'flex',
    alignItems: 'center',
    '& .MuiBadge-badge': {
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "16px",
      color: "#FFFFFF",
      backgroundColor:"var(--website-builder-primary-color)",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "12px",
      }
  },
  });
 
  const styles = getTypographyStyles(isSmallScreen);
  const bgcolor = (templateName===configJSON.themeName.nexus||templateName===configJSON.themeName.radiance)?banner_background:"#ffffff"
  // const getLastAdminRoute = localStorage.getItem('lastAdminRoute') || ""
  const getLastAdminRoute = () => {
    const adminUser = localStorage.getItem('adminuser');
    const lastAdminRoute = localStorage.getItem('lastAdminRoute');
    return adminUser ? (lastAdminRoute || "/admin/Home") : "/admin/EmailAccountLogin";
  }

  return (
    <AppBar position="static" className={classes.appbar} style={{position:((templateName?.trim() === "Merged with banner" || templateName?.trim() === "Merged  with full width banner") && bannerType !== "Cropped" && window.location.pathname === "/" ? "absolute" : 'relative'), background: (headerName?.trim() === "Merged with banner" || templateName?.trim() === "Merged  with full width banner" )&& bannerType !== "Cropped" ? "transparent": (templateName?.trim() === "Static White with full width banner" || templateName?.trim() === "Static White" ? bgcolor :banner_background)}}>

      {isPreviewMode && (
          <Box
            sx={{
              position: "relative",
              bgcolor: bgcolor,
              height : { xs: '154px', sm: '154px', md: '118px', lg: '118px', xl: '118px'},
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row'},
              alignItems: 'center',
              justifyContent : 'center',
              boxShadow: "0px 8px 32px 0px #0000000F",
              gridGap: {xs: '12px', sm: '12px'}
            }}
          >
             <Box style={{
                width: '75%',
                display:'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
                gap: '8px',
             }}>
                <Typography 
                style={{
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  color: '#5500CC',
                  height: '44px',
                  backgroundColor: '#F0E5FF',
                  borderRadius: '4px',
                  ...styles, 
                }}>
                  {configJSON.PreviewMode}
                </Typography>
                <Typography style={{
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#334155',
                }}>
                  {configJSON.helperText}
                </Typography>
             </Box>
             <Link to={getLastAdminRoute()} target="_blank" rel="noopener noreferrer">
             <Box sx={{position: {xs: "static", sm: "static", md: "absolute"}, top: "50%", right: "5%"}}>
               <Typography style={{
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '22px',
                textAlign: 'center',
                color:'#5500CC',
                cursor: 'pointer',
                textDecoration: 'underline'
               }}>{configJSON.backToAdmin}</Typography>
             </Box>
             </Link>
          </Box>
         
      )}

      <Toolbar className={classes.toolbar} style={{background: (headerName?.trim() === "Merged with banner" || templateName?.trim() === "Merged  with full width banner") && bannerType !== "Cropped" ? "transparent": (templateName?.trim() === "Static White with full width banner" || templateName?.trim() === "Static White" ? bgcolor : templateName ? banner_background : "#ffffff") }}>
        {isSmallScreen && isSearcBarOpen ? (
          <>
            <Typography component="div" className={classes.searchbarWrapperDiv}>
              <Box>
                <TextField
                  style={{ position: "relative", zIndex: 9,background:bgcolor }}
                  variant="outlined"
                  inputRef={searchRef}
                  placeholder="Search"
                  onKeyDown={searchForCatalogue}
                  inputProps={{ className: classes.searchBar,background:bgcolor }}
                  onChange={() => searchServiceList(searchRef)}
                  onKeyDownCapture={closeSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        classes={{ positionStart: classes.inputAdornment }}
                      >
                        <img src={SearchBarIconWeb} alt="SearchBarIcon" />
                      </InputAdornment>
                    ),
                    classes: { adornedStart: classes.searchBarIconAdornment },
                  }}
                />
              </Box>

              {loading ? (
                <Skeleton
                  variant="rect"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "180px",
                  }}
                  animation="wave"
                />
              ) : (
                <>
                  <Box
                    className={
                      openClose
                        ? classes.closeBox
                        : classes.serarchListSuggestionHide
                    }
                    onClick={() => {
                      closeSearch();
                      toggleSearchBar();
                    }}
                  ></Box>

                  <Box
                    className={
                      openClose
                        ? classes.serarchListSuggestion
                        : classes.serarchListSuggestionHide
                    }
                  >
                    {searchListSuggestionData.map((element: ServiceList) => {
                      const isMatch = element.title
                        .toLowerCase()
                        .startsWith(searchRef1.toLowerCase());
                      return (
                        <>
                          <Typography
                            onClick={() =>
                              searchElementTitleFunction(
                                element.title,
                                element.is_service
                              )
                            }
                            className={classes.titleName}
                          >
                            <Box
                              className={
                                isMatch ? classes.boldText : classes.normalText
                              }
                            >
                              {isMatch ? (
                                <Box>
                                  <span className={classes.normalText}>
                                    {searchRef1}
                                  </span>
                                  <span className={classes.boldText}>
                                    {element.title.substring(searchRef1.length)}
                                  </span>
                                </Box>
                              ) : (
                                element.title
                              )}
                            </Box>
                          </Typography>
                        </>
                      );
                    })}
                  </Box>
                </>
              )}
            </Typography>
            <CloseIcon
              className={classes.closeIcon}
              onClick={toggleSearchBar}
            />
          </>
        ) : (
          <>
          {/* {renderNavigationHeader()} */}
            <Box className={classes.logoNavigationContainer} >
              <Box className={classes.leftSideContainer}>
                {isSmallScreen && (
                  <IconButton
                    className={classes.menuIcon}
                    onClick={() => toggleDrawer()}
                  >
                    <img src={MenuBarIcon} alt="MenuBarIcon"/>
                  </IconButton>
                )}
                {isExtraSmallScreen && isModalOpen ? (
                  <Typography className={classes.modalHeader}>
                    {configJSON.manageBooking}
                  </Typography>
                ) : (
                  <Box className={classes.logoAppHeader}>
                    <Box onClick={()=> handleNavigation(isPreviewMode? "HomePreview" : "Home")} className={classes.homeLink} data-test-id="logo">
                      {logo &&
                        <Suspense fallback={<CircularProgress />}>
                          <img className={classes.logo} src={logo} height={"50px"} alt="image loading" />
                        </Suspense>}
                          {(show_business_name || !logo && storeName ) && <Typography className={classes.title} style={{color: color, fontFamily: font}}>
                        {storeName}
                      </Typography>
                      }
                    </Box>
                  </Box>
                )}
              </Box>

              <Box>
                {!isSmallScreen && (
                  <Box className={classes.navigationItems}>
                    {drawerItems.map((item, index) => (
                      <Box
                        key={index}
                        className={classes.link}
                        onClick={() => {
                          mixpanel.track(`${item.mixpanel}`, { buildCardID: buildCardID });
                          handleNavigation(item.link);
                        }}
                      >
                        <Typography className={classes.linkText}>
                          {item.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
            
            <Box className={classes.RightSideContainer}>
              {isSmallScreen ? (
                isSearchEnabled &&              
                <img onClick={toggleSearchBar} src={SearchBarIcon} alt="SearchBarIcon" />
              ) : (
                <>
                  {
                    isSearchEnabled &&
                    <Typography
                      component="div"
                      className={classes.searchbarWrapperDiv}
                    >
                      <Box>
                        <TextField
                          style={{ fontSize: "14px", position: "relative", zIndex: 9 }}
                          variant="outlined"
                          inputRef={searchRef}
                          placeholder="Search"
                          onKeyDown={searchForCatalogue}
                          inputProps={{ className: classes.searchBar,background:bgcolor }}
                          onChange={() => searchServiceList(searchRef)}
                          onKeyDownCapture={closeSearch}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                classes={{
                                  positionStart: classes.inputAdornment,
                                }}
                              >
                                <img src={SearchBarIconWeb} alt="SearchBarIcon" />
                              </InputAdornment>
                            ),
                            classes: {
                              adornedStart: classes.searchBarIconAdornment,
                            },
                          }}
                        />
                      </Box>
                      <Box
                        className={
                          openClose
                            ? classes.closeBox
                            : classes.serarchListSuggestionHide
                        }
                        onClick={closeSearch}
                      ></Box>
                      <Box
                        className={
                          openClose
                            ? classes.serarchListSuggestion
                            : classes.serarchListSuggestionHide
                        }
                      >
                        {searchListSuggestionData.map(
                          (element: ServiceList) => {
                            const isMatch = element.title
                              ?.toLowerCase()
                              .startsWith(searchRef1.toLowerCase());
                            return (
                              <Typography
                                key={element.id}
                                onClick={() =>
                                  searchElementTitleFunction(
                                    element.title,
                                    element.is_service
                                  )
                                }
                                className={classes.titleName}
                              >
                                <Box
                                  className={
                                    isMatch
                                      ? classes.boldText
                                      : classes.normalText
                                  }
                                >
                                  {isMatch ? (
                                    <Box>
                                      <span className={classes.normalText}>
                                        {searchRef1}
                                      </span>
                                      <span className={classes.boldText}>
                                        {element.title.substring(
                                          searchRef1.length
                                        )}
                                      </span>
                                    </Box>
                                  ) : (
                                    element.title
                                  )}
                                </Box>
                              </Typography>
                            );
                          }
                        )}
                      </Box>
                    </Typography>
                  }
                  {isBtnVisible ?
                    <Box
                      className={classes.link}
                      onClick={toogleModal}
                      data-test-id="manageBookingBtn"
                    >
                      <Typography className={classes.bookNowButton}>
                        {configJSON.manageBooking}
                      </Typography>
                    </Box>
                    :
                    <Box className={classes.skeletonBox}>
                      <Skeleton
                        variant="rect"
                        style={{
                          position: "absolute",
                          width: "160px",
                          height: "35px",
                        }}
                        animation="wave"
                      />
                    </Box>
                  }
                </>
              )}
                {cartLength > 0 && <CustomCartBadge badgeContent={cartLength} color="primary" overlap="circular" onClick={handleCartNavigation}>
                  <ShoppingCartOutlinedIcon className={classes.cartImage} />
                </CustomCartBadge> }             
            </Box>
          </>
        )}
      </Toolbar>

      {isSmallScreen && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => toggleDrawer()}
          PaperProps={{ className: classes.drawer }}
        >
          <CloseIcon
            className={classes.drawerCloseButton}
            onClick={() => toggleDrawer()}
          />

          <List>
            {drawerItems.map((item, index) => (
              <Box key={index}>
                <ListItem onClick={() => toggleDrawer(true)}>
                  <Box
                    key={index}
                    className={classes.link}
                    onClick={() => {
                      mixpanel.track(`${item.mixpanel}`, { buildCardID: buildCardID });
                      handleNavigation(item.link);
                    }}
                  >
                    <Typography className={classes.linkText}>
                      {item.text}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider className={classes.divider} />
              </Box>
            ))}

            <ListItem>
              <Box className={classes.link}>
                <Typography
                  onClick={handleMobileManageBookingClick}
                  className={classes.bookNowButton}
                >
                  {configJSON.manageBooking}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Drawer>
      )}
      <Modal
        open={isModalOpen}
        onClose={toogleModal}
        className={classes.manageBookingModal}
        hideBackdrop={isExtraSmallScreen}
      >
        <Box className={classes.manageBookingModalContainer}>
          {!isExtraSmallScreen && (
            <Typography className={classes.modalHeader}>
              <Typography data-test-id="manageBookingModalHeader">
                {configJSON.manageBooking}
              </Typography>
              <img
                src={crossIcon}
                alt="closeIcon"
                className={classes.crossIcon}
                onClick={toogleModal}
                width={"14px"}
                height={"14px"}
              />
            </Typography>
          )}
          <Box className={classes.subContainer}>
            <Typography className={classes.modalSubHeader}>
              Enter your Email ID and Booking ID to search for your booking.
            </Typography>
            <Typography className={classes.inputlabel}>Email ID*</Typography>
            <TextField
              variant="outlined"
              placeholder="Email"
              fullWidth
              className={classes.inputField}
              name="email"
              onChange={handleEmailChange}
              error={emailError !== ""}
              helperText={emailError}
              data-test-id="emailInput"
            />
            <Typography className={classes.inputlabel}>Booking ID*</Typography>
            <TextField
              variant="outlined"
              placeholder="Booking ID"
              fullWidth
              className={classes.inputField}
              name="bookingId"
              onChange={handleBookingIdChange}
              error={bookingIdError !== ""}
              helperText={bookingIdError}
              data-test-id="bookingIdInput"
            />
            <Box
              className={classes.link}
              onClick={handleSubmit}
              data-test-id="searchBookingBtn"
            >
              <Typography className={classes.searchBookingButton}>
                Search Booking
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </AppBar>
  );
};

export default Appheader;
// Customizable Area End
