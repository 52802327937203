// Customizable Area Start
export const ArrowIcon = require('../assets/Arrow.png')
export const ArrowForwardIcon = require('../assets/ArrowForwardIcon.png')
export const ArrowBackIcon = require('../assets/ArrowBackIcon.png')
export const CrossMark = require('../assets/ic_CheckMark.png')
export const StaffDummyImage = require('../assets/dummyAvatar.png');
export const CheckMarkCurrect = require('../assets/ic_CheckMarkCurrect.png')
export const Right = require('../assets/right.png')
export const Wrong = require('../assets/wrong.png')
export const DownIcon = require('../assets/downIcon.png')
export const selectedIcon = require("../assets/selected_icon.svg");
export const cartDown = require("../assets/Caret_down.png");
export const cartCloseIcon = require("../assets/close_24px.svg");
export const cartBagIcon = require("../assets/cartBag.svg");
// Customizable Area End