// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RefObject } from "react";
export const configJSON = require("./config");
import { FilterType, OrderType, PaginationType } from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import StorageProvider from "../../../framework/src/StorageProvider";
import { format } from 'date-fns';
// Customizable Area End

export interface Props {
  navigation: {
    navigate: (to: string, params?: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
    classes: Record<string, string>;
  };
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

// Customizable Area Start
export interface PostOrderStatusFailureResponse {
  errors: Array<string>
}

export interface StaffData {
  data: Array<staffDataArray>;
}

export interface staffDataArray {
  id: string;
  type: string;
  isChecked: boolean;
  attributes: {
    id: number,
    full_name: string;
    designation: string;
		slug: string,
		image: string
  },
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
}

interface ServiceProviderAttributes {
  id: number;
  full_name: string;
  designation: string;
  slug: string;
  image: string;
}

interface ServiceProvider {
  id: string;
  type: string;
  attributes: ServiceProviderAttributes;
}

interface Customer {
  id: number;
  full_phone_number: string;
  email: string;
  full_name: string;
  created_at: string; 
  updated_at: string; 
  appointment_id: number;
  comment: string;
}
export interface ServiceProviders {
  id: string;
  full_name: string;
  image: string;
}

interface Service {
  id: number;
  title: string;
  price: number;
  duration: number;
  discount_price: number;
  discount_option: boolean;
}

interface Country {
  id: number;
  name: string;
  code: string;
  phone_code: string;
}

interface Currency {
  id: number;
  name: string;
  symbol: string;
}
interface TimeSlot {
  id: number;
  slot_start_time: string;
  slot_end_time: string;
  is_available: boolean;
}

interface OrderOtherAttributes {
  status: string;
  total: number;
  booking_type: string;
  order_number: string;
  order_date: string;
  appointment_date: string;
  customer: Customer;
  service_provider: ServiceProviders;
  service: Service;
  country: Country;
  currency: Currency;
  time_slot: TimeSlot;
  duration: string;
}

interface Orders {
  id: string;
  type: string;
  attributes: OrderOtherAttributes;
}

interface AppointmentServices {
  id: string;
  type: string;
  attributes: {
    appointment_id: number;
    catalogue_id: number;
    time_slot_id: number;
    service_provider_id: number | null;
    title: string;
    service_status: string;
    catalogue_price: number;
    discount_price: number;
    discount_option: boolean;
    total: number;
    duration: number;
    images: string;
    appointment_date: string;
    appointment_datetime: string;
    payment_mode: string;
    appointment_datetime_tz: string;
    service_provider: {
      id: number;
      full_name: string;
      designation: string;
      image: {
        id: number;
        url: string;
        webp_url: string | null;
      }[];
    };
    time_slot: TimeSlot;
    appointment: {
      status: string;
      total: number;
      to_be_paid_online: number;
      to_be_paid_later: number;
      payment_mode: string;
      personal_detail: {
        id: number;
        full_phone_number: string;
        email: string;
        full_name: string;
        created_at: string;
        updated_at: string;
        appointment_id: number;
        comment: string;
      };
    };
    country: Country;
    currency: Currency;
  }
}

interface AppointmentSummaryRes {
  appointment_id: number;
  catalogue_id: number;
  time_slot_id: number;
  service_provider_id: number | null;
  title: string;
  service_status: string;
  catalogue_price: number;
  discount_price: number;
  discount_option: boolean;
  total: number;
  duration: number;
  images: string;
  appointment_date: string;
  appointment_datetime: string;
  appointment_datetime_tz: string;
  payment_mode: string;
  service_provider: {
    id: number;
    full_name: string;
    designation: string;
    image: {
      id: number;
      url: string;
      webp_url: string | null;
    }[];
  };
  time_slot: TimeSlot;
  appointment: {
    status: string;
    total: number;
    to_be_paid_online: number;
    to_be_paid_later: number;
    payment_mode: string;
    personal_detail: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
      created_at: string;
      updated_at: string;
      appointment_id: number;
      comment: string;
    };
  };
  country: Country;
  currency: Currency;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  selectedTime: string;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  orderList_Data: OrderType[];
  isDialogOpen: boolean;
  updateAppointmentSuccess:boolean;
  searchQuery: string;
  eventCatalogueId:any,
  serviceStaffId:any,
  adminSearch?: string;
  isLoading: boolean;
  isOrderBlank: boolean;
  isFormChanged: boolean;
  filters?: FilterType;
  isCsvDownloading: boolean;
  pagination: PaginationType;
  isOpen: boolean;
  isTotalOpen: boolean;
  isDateOpen: boolean;
  catalogueId:any;
  deletionAppointmentError: any;
  isServiceProviderOpen: boolean;
  buildCardID: string;
  selectedDate: Date | null;
  selectNewDate:any;
  isOpenFilter: boolean;
  isOpenServices: boolean;
  highlightedDate: Date | null;
  servicesList: ServiceProvider[];
  serviceSlotBooking: Array<AppointmentServices>;
  staffListData: Array<staffDataArray>;
  staffId:any;
  serviceTimeSlotData: Array<TimeSlot>;
  staffTimeSlotData: Array<TimeSlot>;
  serviceId:any;
  showEditForm: boolean;
  appointmentSummaryData: AppointmentSummaryRes;
  appointmentServiceId:number;
  serviceProviderData: string;
  isModalOpened: boolean;
  currentView: string;
  showServiceProvider: boolean;
  selectedFieldDate: any;
  selectedFieldFormatedTime: any;
  staffName:string;
  showFiltersData:boolean;
  serviceProviderId: string;
  newServiceProviderData: string;
  appointmentCurrency: any;
  selectedTimeDate: string;
  showDeleteDialog: boolean;
  selectedId: any;
  appointmentId: string;
  timeSlotDate: any;
  filterData:AppointmentServices[];
  editAppointmentStaff: Array<ServiceProviders>;
  cancelAppointmentStatus: string;
  isSlotPresent: string;
  currencyType: string;
  isDisable: boolean;
  // Customizable Area End
}

interface SS { }
export default class CalendarViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  orderCalendarGetApiCallId: string = "";
  updateAppointmentApiCallId: string = "";
  deleteApiCallId: string = "";
  orderServicesGetApiCallId: string = "";
  serviceProviderGetApiCallId: string = "";
  serviceGetApiCallId: string = "";
  staffEditAppointmentGetApiCallId:string = "";
  serviceStaffFilterApiCallId:string = "";
  appointmentSummaryGetApiCallId: string = "";
  orderStatusGetApiCallId: string = "";
  appointmentGetApiCallId: string = "";
  getStaffListingApiCall: string = "";
  // Customizable Area End
  queryRef: React.RefObject<HTMLInputElement>;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.queryRef = React.createRef();
    
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    
    this.submitButtonRef = React.createRef();
    this.resetButtonRef = React.createRef();

    this.state = {
      showDeleteDialog: false,
      selectedTime: "10:00 AM-11:00 PM",
      isModalOpened: false,
      selectedDate: new Date(),
      selectNewDate:"",
      isOpenFilter: false,
      serviceSlotBooking: [],
      isOpenServices: false,
      token: "",
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      orderList_Data: [],
      isDialogOpen: false,
      searchQuery: '',
      isLoading: false,
      isOrderBlank: false,
      isFormChanged: false,
      isCsvDownloading: false,
      isOpen: true,
      isTotalOpen: true,
      isDateOpen: true,
      updateAppointmentSuccess : false,
      pagination: {} as PaginationType,
      deletionAppointmentError: "",
      isServiceProviderOpen: true,
      buildCardID: "",
      highlightedDate: new Date(),
      servicesList: [],
      staffListData: [],
      staffId:[],
      serviceId:[],
      serviceTimeSlotData: [],
      staffTimeSlotData: [],
      showEditForm: false,
      eventCatalogueId:"",
      serviceStaffId:"",
      appointmentSummaryData: {} as AppointmentSummaryRes,
      appointmentServiceId:0,
      serviceProviderData: "",
      currentView: "daily",
      showServiceProvider: false,
      selectedFieldDate: "",
      selectedFieldFormatedTime: "",
      staffName:"",
      showFiltersData:false,
      catalogueId:"",
      serviceProviderId: "",
      newServiceProviderData: "",
      appointmentCurrency: "",
      selectedTimeDate: "",
      selectedId: "",
      appointmentId: "",
      timeSlotDate: "",
      filterData:[],
      editAppointmentStaff:[],
      cancelAppointmentStatus: "",
      isSlotPresent: "",
      currencyType: "",
      isDisable: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start  
  downloadCSVMessageId: string = "";
  async componentDidMount() {
    super.componentDidMount();
    const buildCardID = await StorageProvider.get("buildCardID");
    const currency = await StorageProvider.get("currency_type");
    this.setState({
      buildCardID: buildCardID,
      currencyType: currency
    });
    this.getServicesList();
    this.getToken();
    this.getCalendarListOrder();
    this.getStaffListing();
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleDownloadCSV(message);
      if (responseJson.data) {
        if(apiRequestCallId === this.updateAppointmentApiCallId){
          this.handleUpdateResponse()
        }
        if(apiRequestCallId === this.deleteApiCallId){
          this.setState({ cancelAppointmentStatus: responseJson.data.attributes.status })
          this.handleUpdateResponse();
        } else if (apiRequestCallId === this.orderCalendarGetApiCallId) {
          this.setState({ serviceSlotBooking: responseJson.data })
        } else if (apiRequestCallId === this.orderServicesGetApiCallId) {
          this.setState({ servicesList: responseJson.data })
        } else if (apiRequestCallId === this.serviceGetApiCallId) {
          this.setState({ serviceTimeSlotData: responseJson.data.attributes.time_slots, isSlotPresent: "" })
        } else if (apiRequestCallId === this.serviceProviderGetApiCallId) {
          this.setState({ staffTimeSlotData: responseJson.data.attributes.time_slots, isSlotPresent: "" })
        } else if(apiRequestCallId === this.staffEditAppointmentGetApiCallId){
          this.setState({editAppointmentStaff  : responseJson.data})
        } else if (apiRequestCallId === this.serviceStaffFilterApiCallId) {
          this.setState({ filterData: responseJson.data, isLoading: true})
        } else if (apiRequestCallId === this.appointmentSummaryGetApiCallId) {
          this.setState({ appointmentSummaryData: responseJson?.data?.attributes })
          this.getStaffEditAppointment(this.state.appointmentSummaryData?.catalogue_id);

          this.setState({
            selectedFieldDate: this.state.appointmentSummaryData?.appointment_datetime_tz,
            selectedFieldFormatedTime: responseJson?.data?.attributes?.appointment_datetime_tz, 
            appointmentServiceId:this.state.appointmentSummaryData?.catalogue_id})
          this.setState({ serviceProviderData: this.state.appointmentSummaryData?.service_provider?.full_name })
          this.setState({ 
            catalogueId :this.state?.appointmentSummaryData?.catalogue_id
           })
        } else if (apiRequestCallId === this.getStaffListingApiCall) {
          this.getStaffListingSuccess(responseJson)
        }
      } 
      else if (responseJson?.errors) {
        if (apiRequestCallId === this.serviceGetApiCallId || apiRequestCallId === this.serviceProviderGetApiCallId) {
          mixpanel.track(`${configJSON.errorPastDate}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        } else if (apiRequestCallId === this.orderStatusGetApiCallId) {
          this.handlePostOrderStatusFailureResponse(responseJson);
          this.setState({ isLoading: false });
          mixpanel.track(`${configJSON.errorOrderStatus}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        } else if (apiRequestCallId === this.appointmentGetApiCallId) {
          mixpanel.track(`${configJSON.errorGetAppointment}`, { buildCardID: this.state.buildCardID, error: responseJson?.errors });
        } else if(apiRequestCallId === this.deleteApiCallId){
          this.handlePostOrderStatusFailureResponse(responseJson);
          // this.setState({ isLoading: false });
        } else if (apiRequestCallId === this.updateAppointmentApiCallId) {
          this.setState({ updateAppointmentSuccess: false, deletionAppointmentError: responseJson.errors }, () => {
            setTimeout(() => {
              this.setState({ deletionAppointmentError: "" })
            }, 3000);
          });
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
          this.setState({ isLoading: false });
        }
      } 
      else if (responseJson?.message) {
        if (apiRequestCallId === this.serviceGetApiCallId) {
          this.setState({ isSlotPresent: responseJson?.message, serviceTimeSlotData: [] })
        }
        else if (apiRequestCallId === this.serviceProviderGetApiCallId) {
          this.setState({ isSlotPresent: responseJson?.message, staffTimeSlotData: []})
        }
      }
    }
  };

  callApi = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData("admintoken")
    const header = {
      "Content-Type": "application/json",
      token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  handleEventClick = async (event: React.FormEvent<HTMLInputElement> | any) => {
    if(event?.start){
      const startDate = new Date(event?.start);
      const currentDate = new Date();
      if (startDate < currentDate) {
        this.setState({isDisable: true});
      } else {
        this.setState({isDisable: false});
      }
    }
    await setStorageData("orderID", event?.appointmentId?.toString());
    const eventId = event.id;
    const timeSlotDates = event?.timeSlotDate;
    const timeSlotId = event?.timeSlotId;
    const eventCatalogue = event.catalogueId;
    const serviceProvider = event.serviceProviderId;
    const staffName = event.staffName;
    
    this.setState({ timeSlotDate: timeSlotDates,
      eventCatalogueId: eventCatalogue,
      serviceStaffId: serviceProvider,
      serviceProviderId: serviceProvider,
      serviceProviderData: staffName,
      selectedTimeDate: timeSlotId || this.getDefaultTimeSlotId()
    }, () => {
      this.state.serviceProviderData ? this.getServiceProviderTime() : this.getServiceTime();
    });
    this.setState({ selectedId: eventId, appointmentId: event?.appointmentId?.toString() })
    this.setState({ showEditForm: true })
    this.getAppointmentSummary(eventId);
  };

  getDefaultTimeSlotId = () => {
    const { staffTimeSlotData } = this.state;
    const firstAvailableOption = staffTimeSlotData.find(option => option.is_available);
    return firstAvailableOption ? firstAvailableOption.id : null;
  }

  navigateToCreateBooking = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "CreateBooking");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigateToOrderDetails = async () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList/OrderManagementDetails");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  addOrdinalSuffix = (day:any) => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  };
  
  formatDFateWithOrdinal = (date:any) => {
    const parsedDate = date instanceof Date ? date : new Date(date);

    if (isNaN(parsedDate.getTime())) return '';

    const day = format(parsedDate, 'd');
    const month = format(parsedDate, 'MMMM');
    const year = format(parsedDate, 'yyyy');
    const weekday = format(parsedDate, 'EEE');
    const monthNew = format(parsedDate, 'MM');

    return this.state.currencyType == "CHF" ? `${weekday}, ${(day)}/${monthNew}/${year}`
      : `${weekday}, ${this.addOrdinalSuffix(day)} ${month} ${year}`;
  };

  formatTimeSlotDate(dateString: any) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  handleCheckboxClick = (id: string) => {
    this.setState({ showFiltersData: true });
    
    this.setState((prevState) => {
      const { staffId } = prevState;
      const updatedStaffId = staffId.includes(id)
        ? staffId.filter((staffId: string) => staffId !== id)
        : [...staffId, id];

      return { staffId: updatedStaffId };
    }, () => {
        this.getServiveStaffFilterApi();
    });
  };

  handleServiceCheckboxClick = (id: string) => {
    this.setState({ showFiltersData: true });
  
    this.setState((prevState) => {
      const { serviceId } = prevState;
      const updatedServiceId = serviceId.includes(id)
        ? serviceId.filter((serviceId: string) => serviceId !== id)
        : [...serviceId, id];
  
      return { serviceId: updatedServiceId };
    }, () => {
      if (this.state.serviceId.length === 0 && this.state.staffId.length === 0) {
        this.setState({ showFiltersData: false });
      } else {
        this.getServiveStaffFilterApi();
      }
    });
  };

  handleStaffChange = (event:React.FormEvent<HTMLInputElement> | any) => {
      this.setState({ serviceProviderId: event.target.value }, () => {
      this.getServiceProviderTime();
    });
  };

  handlePostOrderStatusFailureResponse = (failureResponse: PostOrderStatusFailureResponse) => {
    this.setState({
      deletionAppointmentError: failureResponse.errors
    }, () => {
      setTimeout(() => {
        this.setState({ deletionAppointmentError: "" });
      }, 4000);
    })
  };

  handleUpdateResponse = () => {
    this.getServiveStaffFilterApi();
    this.setState({ updateAppointmentSuccess: true }, () => {
      setTimeout(() => {
        this.setState({ updateAppointmentSuccess: false, cancelAppointmentStatus: "" });
      }, 4000);
    })
  };
 
  handleDateChange = async (date: any) => {
      this.setState({
        filterData: [],
        showFiltersData: true,
        selectedDate: new Date(String(date)),
        selectNewDate : new Date(String(date)),
        highlightedDate: new Date(String(date)),
        isLoading: true
      },() => this.getServiveStaffFilterApi())
    
  };
  
  handleFieldDateChange = (date: Date | null) => {
    this.setState({ selectedFieldDate: date, timeSlotDate: date }, () => {
      this.state.serviceProviderData ? this.getServiceProviderTime() : this.getServiceTime();
    })
  }

  handleTimeChange = (event: React.FormEvent<HTMLInputElement> | any) => {
    this.setState({ selectedTimeDate: event.target.value })
  };

  getStaffListing = async () => {
    this.getStaffListingApiCall = await this.callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.getStaffList
    })
  };

  getStaffListingSuccess = (responseJson: StaffData) => {
    let responseData = responseJson.data;

    responseData.forEach((item: staffDataArray) => {
      item["isChecked"] = false
    });
    this.setState({ staffListData: responseData })
  };

  handleDeleteDialogClose = () => {
    this.setState({
      showDeleteDialog: false,
    });
  };

  handleConfirmDelete = async (id: string) => {
    this.setState({
      showDeleteDialog: false,
      showEditForm: !this.state.showEditForm
    });
    this.deleteEditAppointmentData(id);
  };
  
  getWeekStartAndEndDates = () => {
    const today = new Date();
    const currentDay = today.getDay();
    
    const diffToMonday = (currentDay + 6) % 7;

    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - diffToMonday);
    startOfWeek.setHours(0, 0, 0, 0);
    
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
    
    return {
        start: startOfWeek,
        end: endOfWeek
    };
  };

  getCalendarListOrder = async () => {
    const weekDates = this.getWeekStartAndEndDates();
    const startDate = moment.utc(weekDates.start).format("DD-MM-YYYY");
    const endDate = moment.utc(weekDates.end).format("DD-MM-YYYY");

    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    this.orderCalendarGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `${configJSON.appointmentServiceListFilterEndPoint}?[start_date]=${startDate}&[end_date]=${endDate}&show_all=true`,
      contentType: configJSON.apiContentType
    });
  };

  getServicesList = async () => {
    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    const header = {};
    this.orderServicesGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.servicesListEndPoint,
      contentType: configJSON.apiContentType
    });
  };

  formatDateAppointment(dateString: any) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  getServiveStaffFilterApi = async () => {
    this.setState({ isLoading: false})
    const formatDate = (date:any) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
    
      return `${year}-${month}-${day}`;
    };

    const givenDate = moment(this.state.selectNewDate);
    const startOfWeek = givenDate.clone().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = givenDate.clone().endOf("week").format("YYYY-MM-DD");

    const formattedDate = this.state.selectNewDate && formatDate(this.state.selectNewDate)
    const {staffId,serviceId} = this.state
    const staffIdData = staffId.length > 0 ? `&[service_provider_id]=${staffId.join(',')}` : "";
    const serviceIdData = serviceId.length > 0 ? `&[service_id]=${serviceId.join(',')}` : "";
    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });

    const manageDate =  formattedDate ? `[start_date]=${startOfWeek}&[end_date]=${endOfWeek}` : ""
    this.serviceStaffFilterApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint :`${configJSON.appointmentServiceListFilterEndPoint}?${manageDate}${staffIdData}${serviceIdData}&show_all=true&test_order=false`,
      contentType: configJSON.apiContentType
    });
  };

  getServiceTime = async () => {
    const {timeSlotDate,eventCatalogueId} = this.state
    const formattedDate = this.formatTimeSlotDate(timeSlotDate)
    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.serviceGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `${configJSON.serviceAvailabilityApiEndPoint}?catalogue_id=${eventCatalogueId}&availability_date=${formattedDate}`,
      contentType: configJSON.apiContentType
    });
  };

  getServiceProviderTime = async () => {
    const {serviceProviderId, timeSlotDate, eventCatalogueId} = this.state;
    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    const formattedDate = this.formatTimeSlotDate(timeSlotDate)
    this.serviceProviderGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `${configJSON.serviceProviderAvailabilityApiEndPoint}?catalogue_id=${eventCatalogueId}&availability_date=${formattedDate}&service_provider_id=${serviceProviderId}`,
      contentType: configJSON.apiContentType
    });
  };

  getStaffEditAppointment = async (id:number) => {
    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.staffEditAppointmentGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `${configJSON.serviceProviderListApiEndPoint}?id=${id}`,
      contentType: configJSON.apiContentType
    });
  };

  updateEditAppointment = async (id: string) => {
    this.setState({ isFormChanged: false });
    const formattedDate = this.formatDateAppointment(this.state.selectedFieldDate);
    const body = {
      "appointment": {
        "reschedule": true,
        "currency": this.state.appointmentSummaryData.currency.name,
        "appointment_services_attributes": [
          {
            "id": this.state.selectedId,
            "time_slot_id": this.state.selectedTimeDate,
            "catalogue_id": this.state.appointmentSummaryData?.catalogue_id,
            "service_provider_id": (this.state.serviceProviderId ? this.state.serviceProviderId : ""),
            "appointment_date": formattedDate
          }
        ],
        "personal_detail_attributes": {
          "full_name": this.state.appointmentSummaryData?.appointment?.personal_detail.full_name,
          "full_phone_number": this.state.appointmentSummaryData?.appointment.personal_detail?.full_phone_number,
          "email": this.state.appointmentSummaryData?.appointment?.personal_detail?.email
        }
      }
    }

    mixpanel.track(`${configJSON.orderDetailUpdate}`, { buildCardID: this.state.buildCardID });

    this.updateAppointmentApiCallId = await this.callApi({
      method: "PUT",
      endPoint: `${configJSON.updateAPICalendarEndPoint}?id=${id}`,
      body
    });
  };

  deleteEditAppointmentData = async (id: string | any) => {
    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    const header = {};
    this.deleteApiCallId = await this.callApi({
      method: "PUT",
      endPoint: `${configJSON.deleteAppointmentApiEndPoint}?id=${id}`,
      contentType: configJSON.apiContentType
    });
  };

  getAppointmentSummary = async (id: number) => {
    mixpanel.track(`${configJSON.orderListEnter}`, { buildCardID: this.state.buildCardID });
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    const header = {};
    this.appointmentSummaryGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: `${configJSON.appointmentServiceDetailEndPoint}/${id}`,
      contentType: configJSON.apiContentType
    });
  };

  handleNavigateToOrderList = async () => {
    const route = "/admin/OrderMangementList"
    localStorage.setItem(
      'lastAdminRoute',
      '/admin/OrderMangementList'
    );
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleNavigateToCalendarList = async () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "CalendarViewList");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  appointment_summary = async (itemId: string | null) => {
    mixpanel.track(`${configJSON.orderDetailEnter}`, { itemId, buildCardID: this.state.buildCardID });
    const header = {};
    const apiEndPoint = configJSON.routeAppointmentSummary + `?id=${itemId}`;
    this.appointmentGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });

  };

  handleDownloadCSV = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.downloadCSVMessageId === apiRequestCallId) {
      this.setState({ isCsvDownloading: false });
      if (responseJson.csv_data) {
        const csvRows = responseJson.csv_data;
        let csvContent = "data:text/csv;charset=utf-8,";

        csvRows.forEach(function (rowArray: string[]) {
          let rowData = rowArray.join(",");
          csvContent += rowData + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", configJSON.textCSVFileName);
        document.body.appendChild(link);
        link.click();
      }
    }
  };

  download = async () => {
    const header = {};
    const apiEndPoint = configJSON.routeDownloadCSV;
    this.downloadCSVMessageId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });
    this.setState({ isCsvDownloading: true });
  };

  submitButtonRef: RefObject<HTMLButtonElement>;
  resetButtonRef: RefObject<HTMLButtonElement>;

  callAPI = async () => {
    const itemId = await getStorageData("orderID")
    this.appointment_summary(itemId)
  };

  formatDate = (inputDate: string): string => {
    const parsedDaties = new Date(inputDate);

    if (isNaN(parsedDaties.getTime())) {
      return "Invalid Date";
    }

    const optiones: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('en-US', optiones);
    let formattedDate = formatter.format(parsedDaties);

    formattedDate = formattedDate.replace(/, (\d{4})/, ' $1,');
    formattedDate = formattedDate.replace(" at", "");
    formattedDate = formattedDate.replace(/(\d{1,2}) /, '$1 ');

    return formattedDate;
  };

  saveChanges = () => {
    if (this.submitButtonRef.current) {
      this.submitButtonRef.current.click();
      this.setState({ isFormChanged: false })

    }
  };

  discardChanges = () => {
    this.setState({ isFormChanged: false });
  };

  handleFieldFocus = () => {
    this.setState({ isFormChanged: true });
  };

  handleCusDetPage = (id: number) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),`CustomerAdminList/${id}`);
    navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(navigation);
  };

  filterOrdersByDate = (filterData: Array<AppointmentServices>, date: string | undefined) => {
    const formatedDate = moment(date).format("YYYY-MM-DD");
    const filteredOrders = filterData.filter((order: { attributes: { appointment_datetime_tz: string | number | Date; }; }) => {
      const appointmentDate = new Date(order.attributes?.appointment_datetime_tz);
      return appointmentDate.toISOString().split('T')[0] === formatedDate;
    });

    const sortedOrders = filteredOrders.sort((firstTime, secondTime) => {
      const timeA = firstTime.attributes.time_slot.slot_start_time;
      const timeB = secondTime.attributes.time_slot.slot_start_time;
      
      return timeA.localeCompare(timeB);
    });

    return sortedOrders;
  };
  // Customizable Area End
}
